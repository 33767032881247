<template>
    <div class="main-wrapper">
        <div class="admin-top">
            <div class="top-left">
                <span class="left-title">脚本创作</span>
            </div>
        </div>
        <el-scrollbar class="script-item" :native="false">
            <div class="script-content">
                <el-form :model="addScriptForm" ref="addScriptForm" :rules="rules" label-position="left"
                         class="student-info">
                    <el-form-item label="脚本标题" :label-width="formLabelWidth" prop="name">
                        <el-input v-model="addScriptForm.name" ref="scriptInput" autocomplete="off"
                                  type="text" placeholder="请输入脚本标题" show-word-limit maxlength="30"
                                  style="width:70%;"></el-input>
                    </el-form-item>
                    <el-form-item label="脚本简介" :label-width="formLabelWidth" prop="script_introduction">
                        <el-input type="textarea" v-model="addScriptForm.script_introduction" autocomplete="off"
                                  placeholder="请填写脚本简介" style="width:70%;" show-word-limit maxlength="150">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="脚本分类" :label-width="formLabelWidth" prop="classification_id">
                        <el-select v-model="addScriptForm.classification_id" placeholder="请选择脚本分类"
                                   @change="classScript($event)" style="width: 70%;">
                            <el-option
                                    v-for="item in selectScript"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上传封面" prop="cover_file" :label-width="formLabelWidth">
                        <el-upload
                                class="imgBox"
                                action
                                accept="image/jpeg,image/png"
                                list-type="picture-card"
                                :show-file-list="false"
                                :http-request="uploadCover"
                                :on-preview="handlePictureCardPreview">
                            <div :class="this.addScriptForm.cover_file?'imgUplod':'bgColor'">
                                <i class="el-icon-upload2"></i>
                                <el-button style="position: relative;font-size: 14px;" size="small" type="text">
                                    {{addScriptForm.cover_file?'重新上传':'上传图片'}}
                                </el-button>
                            </div>
                            <img :src="addScriptForm.cover_file" v-if="addScriptForm.cover_file">
                        </el-upload>
                        <span class="cover-prompt">建议图片尺寸：392*226，图片大小不超过2M</span>
                    </el-form-item>
                    <el-form-item label="脚本内容" :label-width="formLabelWidth" class="content-item" prop="script_content">
                        <div id="help_content"></div>
                    </el-form-item>
                </el-form>
                <div class="script-content-table">
                    <div class="script-content-btn">
                        <el-button size="medium" class="btn-blue-admin" icon="el-icon-plus" @click="addLensBtn">镜头
                        </el-button>
                    </div>
                    <el-table :data="addScriptForm.camera_lens" style="width: 100%; flex: 1" size="medium"
                              :header-cell-style="{fontWeight: 'normal', color: '#222', background: '#D3DDFF',height:'50px'}"
                              :cell-style="{fontSize: '14px',color: '#222',height:'98px'}">
                        <el-table-column prop="number" label="镜头" align="center" width="60"></el-table-column>
                        <el-table-column prop="farewell" label="景别" align="center" width="100"></el-table-column>
                        <el-table-column prop="direction" label="拍摄方向" align="center" width="100"></el-table-column>
                        <el-table-column prop="mirror_movement" label="运镜" align="center" width="100"></el-table-column>
                        <el-table-column prop="content" label="拍摄内容" align="center" width="220"></el-table-column>
                        <el-table-column prop="music_or_subtitle" label="音乐/字幕" align="center" width="120"></el-table-column>
                        <el-table-column prop="time" label="时间" align="center" width="100"></el-table-column>
                        <el-table-column label="操作" align="center" width="100">
                            <template slot-scope="scope">
                                <el-button size="small" class="btn-blue-script" type="text" @click="editScript(scope.$index)">编辑</el-button>
                                <el-button size="small" class="btn-red-script" type="text" @click="deleteScript(scope.$index)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button size="medium" class="btn-blue-admin" @click="addToForm('addScriptForm',1)">生成脚本
                    </el-button>
                    <el-button size="medium" @click="addToForm('addScriptForm', 2)">保存草稿</el-button>
                </div>
            </div>
        </el-scrollbar>
        <!--S 弹窗标题弹窗-->
        <el-dialog :title="addTitleType" :visible.sync="dialogAddScript" custom-class="centerDialog" width="30%"
                   @close="resetForm()" :close-on-click-modal="false">
            <el-form :model="addForm" ref="addForm" :rules="rules" label-position="center" class="info-form">
                <el-form-item label="镜头数" :label-width="formLabelWidth82" prop="number">
                    <el-input type="number" v-model.number="addForm.number" autocomplete="off"
                              placeholder="请输入镜头数"
                              style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="景别" :label-width="formLabelWidth82" prop="farewell">
                    <el-input v-model="addForm.farewell" autocomplete="off" placeholder="请输入景别1-20字"
                              style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="拍摄方向" :label-width="formLabelWidth82" prop="direction">
                    <el-input v-model="addForm.direction" autocomplete="off" placeholder="请输入拍摄方向1-200字"
                              style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="运镜" :label-width="formLabelWidth82" prop="mirror_movement">
                    <el-input v-model="addForm.mirror_movement" autocomplete="off" placeholder="请输入运镜"
                              style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="拍摄内容" :label-width="formLabelWidth82" prop="content">
                    <el-input type="textarea" v-model="addForm.content" autocomplete="off" placeholder="请输入拍摄内容1-500字"
                              style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="音乐/字幕" :label-width="formLabelWidth82" prop="music_or_subtitle">
                    <el-input type="textarea" v-model="addForm.music_or_subtitle" autocomplete="off" placeholder="请输入音乐/字幕1-500字"
                              style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="时间" :label-width="formLabelWidth82" prop="time">
                    <el-input v-model="addForm.time" autocomplete="off" placeholder="请输入时间以s为单位" style="width: 80%"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddScript = false">取 消</el-button>
                <el-button size="medium" class="btn-blue-admin" @click="confirmBtn('addForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!--E 弹窗标题弹窗-->
    </div>
</template>

<script>
    import WE from "../../utils/wEditor";

    export default {
        name: "ScriptManage",
        data() {
            let lensNumber = (rule, value, callback) => {
                let isRepeat = false;
                for (let i in this.addScriptForm.camera_lens) {
                    if (this.addScriptForm.camera_lens[i].number === value && this.editScriptIndex !== Number(i)) {
                        isRepeat = true;
                        break;
                    }
                }
                if (isRepeat) {
                    return callback(new Error("镜头数不能重复"));
                } else {
                    callback();
                }

            };

            let lensTime = (rule, value, callback) => {
                if (value.indexOf("-") > -1) {
                    let regular = new RegExp(/^[0-9]{1,3}s-[0-9]{1,3}s$/);
                    if (regular.test(value)) {
                        let t = value.split("-");
                        let t1 = parseInt(t[0].replace("s")); 
                        let t2 = parseInt(t[1].replace("s"));
                        if (t2 > t1) {
                            callback();
                        } else {
                            return callback(
                                new Error("格式错误；正确格式如：10s-20s（数字不超过三位数）")
                            );
                        }
                    } else {
                        return callback(
                            new Error("格式错误；正确格式如：100s-200s（数字不超过三位数）")
                        );
                    }
                } else {
                    let regular = new RegExp(/^[0-9]{1,3}s$/);
                    if (regular.test(value)) {
                        callback();
                    } else {
                        return callback(
                            new Error("格式错误；正确格式如：10s（数字不超过三位数）")
                        );
                    }
                }
            };
            return {
                id: Number(this.$route.query.id) || '',
                scriptTable: [],
                selectScript: [],
                scriptTitleType: '',
                //添加标题/镜头弹窗
                addTitleType: '',
                addBtn: '',
                dialogAddScript: false,
                addForm: {},
                addScriptForm: {
                    name: '',
                    script_introduction: '',
                    cover_file: null,
                    classification_id: '',
                    // classification_name: '',
                    script_content: '',
                    camera_lens: []
                },
                dialogScript: false,
                formLabelWidth82: '82px',
                formLabelWidth: '80px',
                //确定/保存
                distinguishBtn: '',
                rules: {
                    number: [
                        {required: true, message: '请输入镜头数', trigger: 'blur'},
                        {type: "number", min: 1, max: 100, message: "镜头数为1-100", trigger: "blur",},
                        {validator: lensNumber, trigger: 'blur'}
                    ],
                    farewell: [
                        {required: true, message: '请输入景别', trigger: 'blur'},
                        {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'}
                    ],
                    direction: [
                        {required: true, message: '请输入拍摄方向', trigger: 'change'},
                        {min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur'}
                    ],
                    mirror_movement: [
                        {required: true, message: '请输入运镜', trigger: 'blur'},
                    ],
                    content: [
                        {required: true, message: '请输入拍摄内容', trigger: 'blur'},
                        {min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur'}
                    ],
                    music_or_subtitle: [
                        {required: true, message: '请输入字幕/音效', trigger: 'blur'},
                        {min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur'}
                    ],
                    //
                    name: [
                        {required: true, message: '请输入脚本标题', trigger: 'blur'},
                        {min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur'}
                    ],

                    time: [
                        {required: true, message: '请输入时间', trigger: 'blur'},
                        { validator: lensTime, trigger: "blur" },
                    ],
                    // script_introduction: [
                    //     {required: true, message: '请填写脚本简介', trigger: 'blur'},
                    //     {min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur'}
                    // ],
                    classification_id: [
                        {required: true, message: '请选择脚本类型', trigger: 'change'},
                    ],
                    cover_file: [
                        {required: true, message: '请上传脚本封面', trigger: 'blur'},
                    ],
                    // script_content: [
                    //     {required: true, message: '请填写脚本内容', trigger: 'blur'},
                    // ]
                },
                editorW: null,
                editScriptIndex: -1, //需要编辑对象的下标，-1代表添加
            }
        },
        created() {
            this.getCurrentList();
        },
        mounted() {
            if (this.$route.query.id) {
                // 获取脚本
                let param = {
                    id: this.$route.query.id,
                    status: 1
                }
                this.$http.axiosGetBy(this.$api.getScript, param, res => {
                        if (res.code === 200) {
                            this.addScriptForm = {
                                name: res.data.name,
                                script_introduction: res.data.script_introduction,
                                cover_file: res.data.cover_url,
                                classification_id: res.data.classification_id>0?res.data.classification_id:'',
                                script_content: res.data.script_content,
                                camera_lens: res.data.camera_lens || []
                            }
                            this.editorW.setWEHtml(res.data.script_content);
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    },
                    err => {
                        console.log(err);
                    });
            }
            this.editorW = new WE('#help_content', this.$api.artUploadImg, ['head', 'bold', 'underline', 'link', 'image']);
        },
        methods: {
            // 获取脚本分类
            getCurrentList() {
                this.$http.axiosGet(this.$api.getClassification, (res) => {
                    if (res.code === 200) {
                        this.selectScript = res.data.data;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 分类选择
            classScript(e) {
                this.addScriptForm.classification_id = e;
            },
            // 上传封面
            uploadCover(param) {
                let file = param.file
                let maxSize = 1024 * 1024 * 2;
                let reader = new FileReader();
                reader.readAsDataURL(file);//读取图像文件 result 为 DataURL, DataURL 可直接 赋值给 img.src
                reader.onload = (event) => {
                    let img = new Image();
                    // console.log(param, "param");
                    img.src = event.target.result;
                    img.onload = () => {
                        if (img.width === 392 && img.height === 226 && file.size <= maxSize) {
                            let fileData = new FormData();
                            fileData.append("image", file);
                            this.$http.axiosPost(this.$api.getCover, fileData, (res) => {
                                    // 封面
                                    if (res.code === 200) {
                                        this.addScriptForm.cover_file = res.data.url;
                                        // 提示上传成功
                                        this.$message({
                                            type: "success",
                                            message: "封面上传成功",
                                        });
                                    } else {
                                        this.$message({
                                            type: "error",
                                            message: res.message,
                                        });
                                    }
                                },
                                (err) => {
                                    this.$message({
                                        type: "error",
                                        message: "请求失败",
                                    });
                                }
                            );
                        } else {
                            this.$message({
                                type: "error",
                                message: "图片尺寸必须为 392*226，大小不能超过2M",
                            });
                        }
                    };

                }


            },
            handlePictureCardPreview(file) {
                // this.addScriptForm.cover_file = file.url;
            },
            //添加
            addLensBtn() {
                this.dialogAddScript = !this.dialogAddScript;
                this.addTitleType = '添加镜头';
                this.addBtn = 'add';
                this.editScriptIndex = -1
            },
            // 编辑
            editScript(index) {
                // console.log(row, 'uu')
                this.dialogAddScript = !this.dialogAddScript;
                this.addTitleType = "编辑镜头";
                this.addBtn = "edit";
                this.editScriptIndex = index;
                this.addForm = {...this.addScriptForm.camera_lens[index]};
            },
            // 根据对象属性排序
            sortByAttribute(property) {
                return function (a, b) {
                    let value1 = a[property];
                    let value2 = b[property];
                    return value1 - value2;
                };
            },
            //添加镜头确定点击事件
            confirmBtn(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let obj = {...this.addForm};
                        if (this.editScriptIndex > -1) {
                            //   编辑
                            this.addScriptForm.camera_lens[this.editScriptIndex] = obj;
                            this.editScriptIndex = -1;
                        } else {
                            //   添加
                            this.addScriptForm.camera_lens.push(obj);
                        }
                        this.addScriptForm.camera_lens.sort(this.sortByAttribute("number"))
                        this.dialogAddScript = false
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            //删除
            deleteScript(index) {
                this.$confirm('是否删除该数据，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.addScriptForm.camera_lens.splice(index, 1);
                }).catch(() => {
                    this.$message.info("已取消删除");
                });
            },
            //重置表单
            resetForm() {
                // this.$refs.addForm.resetFields();
                for (let key in this.addForm) {
                    this.addForm[key] = "";
                }
            },
            //生成脚本
            // type 1- 生成正式脚本 2-保存草稿
            addToForm(formName, type) {
                this.addScriptForm.script_content = this.editorW.getWEHtml();
                if (type === 1) {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            if (this.addScriptForm.camera_lens.length > 0) {
                                this.saveData(type).then((res) => {
                                    if (res.code === 200) {
                                        this.$message({
                                            type: "success",
                                            message: res.message,
                                            duration: 1000,
                                        });
                                        this.$router.push("/admin/script/classification");

                                    } else {
                                        this.$message({
                                            type: "error",
                                            message: res.message,
                                            duration: 1000,
                                        });
                                    }
                                });
                            } else {
                                this.$message({
                                    type: "error",
                                    message: "脚本镜头不能为空",
                                    duration: 1000,
                                });
                            }
                        }
                    });
                } else {
                    if (this.addScriptForm.name) {
                        this.saveData(type).then((res) => {
                            if (res.code === 200) {
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                    duration: 1000,
                                });
                                this.$router.push("/admin/script/classification");
                                localStorage.setItem("activeName", "second");
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.message,
                                    duration: 1000,
                                });
                            }
                        });
                    } else {
                        this.$message({
                            type: "error",
                            message: '请填写标题',
                            duration: 1000,
                        });
                    }

                }
            },
            // 保存数据
            // type 1- 生成正式脚本 2-保存草稿
            saveData(type) {
                return new Promise((resolve, reject) => {
                    let formData = {
                        status: type,
                        name:this.addScriptForm.name,
                        script_introduction:this.addScriptForm.script_introduction,
                        classification_id:this.addScriptForm.classification_id,
                        cover_url:this.addScriptForm.cover_file,
                        script_content:this.addScriptForm.script_content,
                        camera_lens:JSON.stringify(this.addScriptForm.camera_lens)
                    };
                    if (this.id) {
                        formData["id"] = this.id;
                    }
                    // console.log(formData)
                    this.$http.axiosPost(this.$api.getScript, formData, (res) => {
                            resolve(res);
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        .script-item {
            height: 1%;
            flex: 1;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .script-content {
                padding: 0 20px;

                ::v-deep .el-form-item {
                    display: flex;
                    flex-direction: column;
                }

                ::v-deep .el-form-item__content {
                    margin-left: 0 !important;
                }

                ::v-deep .el-upload--picture-card {
                    width: 392px;
                    height: 226px;
                    line-height: 226px;
                }

                .dialog-footer {
                    display: flex;
                    justify-content: center;
                    padding: 20px 0;
                }

                .script-content-table {
                    display: flex;
                    flex-direction: column;

                    .script-content-btn {
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom: 10px;
                    }

                    ::v-deep .el-table--medium td, ::v-deep .el-table--medium th {
                        border-right: 2px solid #fff;
                    }
                    ::v-deep .el-table--medium td{
                        background:#F0F3FF;
                        border-bottom: 2px solid #fff
                    }
                    ::v-deep .el-table__body-wrapper {
                        height: auto;
                    }
                }
            }
        }
        .btn-blue-admin {
            color: #FFF;
            background-color: #574DED;
            border-color: #574DED;
        }
        .btn-blue-admin:hover,
        .btn-blue-admin:focus,
        .btn-blue-admin.current {
            background: #1E33E4;
            border-color: #1E33E4;
            color: #FFF;
        }
        ::v-deep .el-textarea__inner {
            height: 80px;
            resize: none;
        }
        ::v-deep .el-input__inner::-webkit-outer-spin-button,
        ::v-deep .el-input__inner::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0;
        }
        ::v-deep .el-input__inner[type="number"] {
            -moz-appearance: textfield;
        }

        .imgBox {
            /*width: 392px;*/
            /*height: 226px;*/
            .imgUplod {
                display: flex;
                flex-direction: column;
                position: absolute;
                background: rgba(0, 0, 0, .3);
                width: 389px;
                height: 224px;
                /* line-height: 224px; */
                justify-content: center;
                ::v-deep .el-button--text {
                    color: #fff;
                }
                ::v-deep .el-icon-upload2 {
                    color: #fff;
                }
            }
            .bgColor {
                background: #ECEBFF;
                width: 389px;
                height: 224px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                ::v-deep .el-button--text {
                    color: #333;
                }

                ::v-deep .el-upload--picture-card i {
                    color: #333;
                }
            }

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .dialog-footer {
            display: flex;
            justify-content: center;
        }

        .top-left:before {
            content: "";
            position: absolute;
            height: 16px;
            width: 4px;
            background: #1D33E1;
            top: 102px;
            border-radius: 4px;
        }

        .left-title {
            padding-left: 10px;
        }

    }

    ::v-deep .el-table--border::after, ::v-deep .el-table--group::after, ::v-deep .el-table::before {
        background-color: transparent;
    }

    ::v-deep .upload-demo {
        display: flex;
        align-items: center;
    }
    .cover-prompt {
        color: #f56c6c;
    }
    .el-textarea {
        ::v-deep .el-input__count {
            color: #909399;
            background: #FFF;
            position: absolute;
            font-size: 12px;
            bottom: 4px;
            right: 10px;
            height: 20px;
            line-height: 20px;
        }
    }
    ::v-deep .el-table tbody tr:hover > td {
        background-color: transparent;
    }
</style>